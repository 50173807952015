<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="loading">
          <iq-card>
            <template v-slot:body>
              <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                <b-form  @submit.prevent="handleSubmit(register)" >
                  <b-row>
                    <b-col lg="10" sm="12">
                      <ValidationProvider name="Crop Name (En)" vid="crops_name" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="crops_name"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('common_config.crop_name')}} ({{$t('globalTrans.en')}})<span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="fee_name"
                              v-model="cropName.name"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Crop Name (Bn)" vid="crops_name_bn" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="crops_name_bn"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('common_config.crop_name')}}  {{$t('globalTrans.bnn')}}<span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="crops_name_bn"
                              v-model="cropName.name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                      &nbsp;
                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
            </template>
          </iq-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'
import { cropNameStore, cropNameUpdate } from '../../api/routes'
import commonAuth from '../CommonAuth'

export default {
  mixins: [commonAuth],
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      cropName: {
        name: '',
        name_bn: '',
        org_id: 0
      },
      loading: false
    }
  },
  created () {
    this.cropName.org_id = this.userCheck()
    if (this.id) {
      const tmp = this.getcropNameData()
      this.cropName = tmp
    }
  },
  mounted () {
    core.index()
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    }
  },
  methods: {
    getcropNameData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      this.loading = true
      if (this.cropName.id) {
        result = await RestApi.putData(licenseRegistrationServiceBaseUrl, `${cropNameUpdate}/${this.id}`, this.cropName)
      } else {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, cropNameStore, this.cropName)
      }
      this.loading = false
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('licenseRegistration/mutateLicenseRegistrationProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
